import styled from 'styled-components'
import { Button } from '../../atoms/Button'
import { size } from '../../constants'

export const NavigateButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 72px;

  @media (max-width: ${size.md}) {
    margin-bottom: 40px;
    display: block;
  }

  & ${Button.NewOutline} {
    @media (max-width: ${size.md}) {
      width: 100%;
    }
  }
`
