import styled from 'styled-components'
import { size } from '../../constants'

export const HeaderQuestionsTopButtonsContainer = styled.div`
  display: flex;
  & > button {
    margin-right: 16px;
  }

  @media (max-width: ${size.md}) {
    display: block;

    & > button {
      margin-right: 0;
      margin-bottom: 12px;
    }

    & button {
      width: 100%;
      height: 48px;
    }

    & a {
      width: 100%;
      height: 48px;
    }
  }
`
