import React, { useCallback } from 'react'
import styled from 'styled-components'
import useTogglePopup from '../components/Modal/useTogglePopup'
import { Button } from '../atoms/Button'
import { Event as GAEvent } from '../components/GA'
import { GiveGuideModal } from './GiveGuideModal'
import { Grid } from '../atoms/Grid'
import { PublicSubscriptionTypes } from '../api'
import { Text } from '../atoms/Text'
import { Title } from '../atoms/Title'
import { color } from '../styles/vars/colors'
import { size } from '../constants'

const CopeWithAnexity = styled(({ className }) => {
  const { isShowing, tglPopup } = useTogglePopup()
  const handleGetGuideClick = useCallback(() => {
    tglPopup()
    GAEvent.getGuide('click', 'mitigateAnxiety')
  }, [tglPopup])
  return (
    <div className={className}>
      <Title.H3>
        Больше разнообразных техник можно найти в гайде «Как совладать с
        тревогой?»
      </Title.H3>
      <Button.NewPrimary onClick={handleGetGuideClick} size="large">
        Получить гайд
      </Button.NewPrimary>
      {isShowing && (
        <GiveGuideModal
          hide={tglPopup}
          location="mitigateAnxiety"
          type={PublicSubscriptionTypes.ANXIETY_NOTE}
        />
      )}
    </div>
  )
})`
  & > ${Title.H3} {
    margin-bottom: 24px;
  }

  & > ${Button.NewPrimary} {
    @media (max-width: ${size.sm}) {
      width: 100%;
    }
  }
`

const Tips = styled(({ className }) => (
  <div className={className}>
    <Text.Large semiBold>
      Когда тревожные мысли захватывают все пространство в вашем разуме и
      ощущение тревоги только нарастает, может быть очень сложно отстраниться и
      успокоиться.
      <br />
      <br /> Помочь в этом могут быстрые простые упражнения, цель которых —
      снизить интенсивность тревоги, вернуть вам контроль за мыслями. Одна из
      таких техник — поиск предметов.
    </Text.Large>
  </div>
))`
  width: 100%;
`

const Practice = styled(({ className }) => (
  <div className={className}>
    <Title.H2>Упражнение</Title.H2>
    <Text.Large semiBold>
      Начните искать три зеленых предмета вокруг вас. Каждый раз, когда вы
      находите зеленый предмет, назовите его вслух.
      <br />
      <br /> Если зеленый часто встречается вокруг вас, то выберите более редкий
      цвет (например, фиолетовый) или увеличьте количество предметов, которых
      нужно найти.
      <br />
      <br /> Можно фокусироваться и на других органах чувств: искать звуки,
      запахи или телесные ощущения.
    </Text.Large>
  </div>
))`
  background: #e3f5f5;
  border-radius: 24px;
  padding: 24px;
  height: fit-content;

  & > ${Title.H2} {
    margin-bottom: 24px;
  }
`

const TextContent = styled.div`
  display: grid;
  grid-template-columns: 588px 484px;
  row-gap: 48px;
  column-gap: 24px;

  @media (max-width: ${size.lg}) {
    grid-template-columns: 461px 468px;
    row-gap: 40px;
    column-gap: 30px;
  }

  @media (max-width: ${size.md}) {
    grid-template-columns: 100%;
    row-gap: 0;
  }

  ${Tips} {
    grid-column: 1;
    grid-row: 1 / span 1;

    @media (max-width: ${size.md}) {
      margin-bottom: 24px;
    }
  }

  ${CopeWithAnexity} {
    grid-column: 1;
    grid-row: 2 / span 1;

    @media (max-width: ${size.md}) {
      grid-row: 3 / span 1;
    }
  }

  ${Practice} {
    grid-column: 2;
    grid-row: 1 / span 2;

    @media (max-width: ${size.md}) {
      grid-column: 1;
      grid-row: 2 / span 1;
      margin-bottom: 32px;
    }

    @media (max-width: ${size.sm}) {
      margin-bottom: 24px;
    }

    @media (max-width: ${size.xs}) {
      margin-bottom: 16px;
    }
  }
`

export const ReduceAnxiety = styled(({ className }) => (
  <section className={className}>
    <Grid>
      <Title.H2asH1styles>
        Как снизить тревогу «здесь и сейчас»?
      </Title.H2asH1styles>
      <TextContent>
        <Tips />
        <CopeWithAnexity />
        <Practice />
      </TextContent>
    </Grid>
  </section>
))`
  background: ${color.background};
  padding-bottom: 122px;
  max-width: 100vw;

  @media (max-width: ${size.lg}) {
    padding-bottom: 64px;
  }

  @media (max-width: ${size.md}) {
    padding-bottom: 40px;
  }

  @media (max-width: ${size.xs}) {
    padding-bottom: 32px;
  }

  & > ${Grid} > ${Title.H2asH1styles} {
    margin-bottom: 40px;

    @media (max-width: ${size.md}) {
      margin-bottom: 32px;
    }

    @media (max-width: ${size.sm}) {
      margin-bottom: 16px;
    }
  }
`
