import React, { useCallback } from 'react'
import cloudsBackground from './img/clouds.svg'
import cloudsBackgroundMobile from './img/clouds-mobile.svg'
import styled from 'styled-components'
import useTogglePopup from '../../components/Modal/useTogglePopup'
import { Button } from '../../atoms/Button'
import { ReactComponent as CloudSVG } from './img/cloud-icon.svg'
import { Event as GAEvent } from '../../components/GA'
import { ReactComponent as GirlMobileSVG } from './img/girl-mobile.svg'
import { ReactComponent as GirlSVG } from './img/girl.svg'
import { GiveGuideModal } from '../GiveGuideModal'
import { Grid } from '../../atoms/Grid'
import { HeaderQuestionsTopButtonsContainer } from './HeaderQuestions.styles'
import { ReactComponent as LightningSVG } from './img/lightning.svg'
import { PublicSubscriptionTypes } from '../../api'
import { Text } from '../../atoms/Text'
import { Title } from '../../atoms/Title'
import { color } from '../../styles/vars/colors'
import { size } from '../../constants'

const GirlDecorate = styled(GirlSVG)``
const GirlMobileDecorate = styled(GirlMobileSVG)``
const CloudIcon = styled(CloudSVG)``
const LightningIcon = styled(LightningSVG)``

export const HeaderQuestionsTop = styled(({ className }) => {
  const { isShowing, tglPopup } = useTogglePopup()
  const handleGetGuideClick = useCallback(() => {
    tglPopup()
    GAEvent.getGuide('click', 'firstScreen')
  }, [tglPopup])

  return (
    <section className={className}>
      <Grid>
        <Title.H1>
          Как справиться
          <br /> с тревожностью?
          <br />
          Психологическая <br /> помощь
          <br /> при тревоге
        </Title.H1>
        <Text.Large semiBold>
          Получите рекомендации психологов&nbsp;YouTalk
          <br /> как справляться с тревогой,
          <br /> тревожностью и беспокойством
        </Text.Large>
        <HeaderQuestionsTopButtonsContainer>
          <Button.NewPrimary onClick={handleGetGuideClick} size="large">
            Получить гайд
          </Button.NewPrimary>
          <Button.NewOutline
            href="/wizard/"
            onClick={() => {
              GAEvent.openNavigate('anxietyLanding')
            }}
            size="large"
            type="link"
          >
            Подобрать психолога
          </Button.NewOutline>
        </HeaderQuestionsTopButtonsContainer>
        <GirlDecorate />
        <GirlMobileDecorate />
        {isShowing && (
          <GiveGuideModal
            hide={tglPopup}
            location="firstScreen"
            type={PublicSubscriptionTypes.ANXIETY_NOTE}
          />
        )}
      </Grid>
    </section>
  )
})`
  background: #e6f0fd;

  & ${Grid} {
    padding-top: 64px;
    padding-bottom: 77px;
    position: relative;

    @media (max-width: ${size.lg}) {
      padding-top: 42px;
      padding-bottom: 70px;
    }

    @media (max-width: ${size.md}) {
      padding-top: 40px;
      padding-bottom: 88px;
    }

    @media (max-width: ${size.sm}) {
      padding-top: 32px;
      padding-bottom: 203px;
    }

    @media (max-width: ${size.xs}) {
      padding-bottom: 251px;
    }
  }

  & > ${Grid}::before {
    content: '';
    top: 0;
    right: -110px;
    position: absolute;
    width: 100%;
    height: 100%;
    background-image: url(${cloudsBackground});
    background-position: top right;
    background-size: contain;
    background-repeat: no-repeat;
    z-index: 0;

    @media (max-width: ${size.lg}) {
      top: -102px;
      right: -227px;
    }

    @media (max-width: ${size.md}) {
      top: 36px;
    }

    @media (max-width: ${size.sm}) {
      height: calc(100% / 2);
      bottom: 20px;
      left: 0;
      right: unset;
      top: unset;
      background-image: url(${cloudsBackgroundMobile});
    }

    @media (max-width: ${size.xs}) {
      bottom: 0;
    }
  }

  & > ${Grid} > ${Title.H1} {
    position: relative;
    z-index: 1;
    margin-bottom: 24px;

    @media (max-width: ${size.md}) {
      margin-bottom: 16px;
    }

    @media (max-width: ${size.xs}) {
      margin-bottom: 8px;
    }
  }

  & > ${Grid} > ${Text.Large} {
    position: relative;
    z-index: 1;
    margin-bottom: 32px;

    @media (max-width: ${size.md}) {
      margin-bottom: 24px;
    }

    @media (max-width: ${size.xs}) {
      margin-bottom: 16px;
    }
  }

  & > ${Grid} > ${Button.NewPrimary} {
    @media (max-width: ${size.sm}) {
      width: 100%;
    }
  }

  & > ${Grid} ${GirlDecorate} {
    position: absolute;
    top: 106px;
    left: 492px;
    z-index: 3;

    @media (max-width: ${size.lg}) {
      left: 430px;
      top: 82px;
    }

    @media (max-width: ${size.md}) {
      transform: scale(0.7);
      top: 118px;
      left: 262px;
    }

    @media (max-width: ${size.sm}) {
      display: none;
    }
  }

  & > ${Grid} ${GirlMobileDecorate} {
    position: absolute;
    display: none;
    z-index: 3;

    @media (max-width: ${size.sm}) {
      display: block;
      left: 0;
      right: 0;
      margin: 0 auto;
      top: 477px;
    }

    @media (max-width: ${size.xs}) {
      top: 485px;
    }
  }

  ${Button.NewOutline} {
    position: relative;
    z-index: 1;
  }
}
`

const MarkedList = styled(({ className }) => (
  <ul className={className}>
    <li>
      <LightningIcon />
      <Text.Large bold>
        Сложно наслаждаться моментом и быть счастливым, когда в голове мысли
        жужжат, словно мухи.
      </Text.Large>
    </li>
    <li>
      <CloudIcon />
      <Text.Large bold>
        Навязчивые мысли о том, что вы опоздаете, будете выглядеть глупо,
        заболеете, упадете в обморок или опозоритесь забирают время и силы.
      </Text.Large>
    </li>
  </ul>
))`
  display: flex;
  flex-direction: column;
  gap: 54px;

  @media (max-width: ${size.md}) {
    display: grid;
    grid-template-columns: repeat(2, 293px);
    gap: 77px;
  }

  @media (max-width: ${size.sm}) {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }

  @media (max-width: ${size.xs}) {
    gap: 16px;
  }

  & > li {
    color: ${color.text.white};
    display: grid;
    grid-template-columns: 78px 384px;
    gap: 24px;

    @media (max-width: ${size.md}) {
      grid-template-columns: 78px 368px;
    }

    @media (max-width: ${size.md}) {
      grid-template-columns: unset;
      grid-template-rows: 78px auto;
      gap: 16px;
    }

    @media (max-width: ${size.sm}) {
      grid-template-columns: 78px auto;
      grid-template-rows: unset;
      gap: 16px;
    }
  }
`
const QuestionAnswer = styled(({ className }) => (
  <div className={className}>
    <Title.H2asH1styles>
      К чему <br />
      приводит тревога <br />и беспокойство?
    </Title.H2asH1styles>
    <Text.Large noResize semiBold>
      Тревога знакома каждому, но для некоторых
      <br /> людей тревожность — постоянный спутник
      <br /> жизни.
    </Text.Large>
  </div>
))`
  display: flex;
  flex-direction: column;
  gap: 24px;

  @media (max-width: ${size.lg}) {
    gap: 16px;

    ${Text.Large} {
      & br {
        display: none;
      }
    }
  }

  ${Title.H2asH1styles}, ${Text.Large} {
    color: ${color.text.white};
  }
`

export const HeaderQuestionsBottom = styled(({ className }) => (
  <section className={className}>
    <Grid>
      <QuestionAnswer />
      <MarkedList />
    </Grid>
  </section>
))`
  width: 100%;
  padding-bottom: 94px;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    height: calc(100% + 8px);
    width: 100vw;
    background: #0063a8;
  }

  @media (max-width: ${size.lg}) {
    padding-bottom: 53px;
  }

  @media (max-width: ${size.md}) {
    padding-bottom: 36px;
  }

  @media (max-width: ${size.sm}) {
    padding-bottom: 42px;
  }

  @media (max-width: ${size.sm}) {
    padding-bottom: 27px;
  }

  ${Grid} {
    padding-top: 108px;
    display: flex;
    flex-direction: row;
    gap: 130px;

    @media (max-width: ${size.lg}) {
      padding-top: 88px;
      gap: 22px;
    }

    @media (max-width: ${size.md}) {
      padding-top: 64px;
      gap: 32px;
      flex-direction: column;
    }

    @media (max-width: ${size.sm}) {
      padding-top: 96px;
      gap: 24px;
    }

    @media (max-width: ${size.xs}) {
      padding-top: 78px;
      gap: 16px;
    }
  }
`

export const HeaderQuestions = () => (
  <>
    <HeaderQuestionsTop />
    <HeaderQuestionsBottom />
  </>
)
