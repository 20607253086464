import React from 'react'
import styled from 'styled-components'
import videoCallSVG from './img/video-call.svg'
import { Grid } from '../../atoms/Grid'
import { StatisticNumbersList } from './StatisticNumbersList'
import { Text } from '../../atoms/Text'
import { Title } from '../../atoms/Title'
import { color } from '../../styles/vars/colors'
import { size } from '../../constants'

const VideoCallDecorate = styled(({ className }) => (
  <img
    alt="видеозвонок двух девушек"
    className={className}
    src={videoCallSVG}
  />
))``

const TextContent = styled(({ className }) => (
  <div className={className}>
    <VideoCallDecorate />
    <Title.H2>
      Психолог сможет подобрать техники и темп, которые подойдут конкретно вам.
    </Title.H2>
    <div>
      <Text.Large noResize semiBold>
        Сможет заметить малоэффективные для вас методы и вовремя их заменить.
      </Text.Large>
      <Text.Large noResize semiBold>
        Более того, специалист вовремя заметит, если на вашу тревогу влияют
        события в вашей жизни, когнитивные искажения или другие состояния.
        Например, депрессия или выгорание.
      </Text.Large>
    </div>
  </div>
))`
  width: 588px;
  margin-right: 0;
  margin-left: auto;
  position: relative;

  @media (max-width: ${size.md}) {
    width: 418px;
  }

  @media (max-width: ${size.sm}) {
    width: 100%;
  }

  ${Title.H2} {
    margin-bottom: 24px;

    @media (max-width: ${size.md}) {
      margin-bottom: 16px;
    }
  }

  & div {
    display: flex;
    flex-direction: column;
    gap: 16px;

    @media (max-width: ${size.md}) {
      gap: 8px;
    }
  }

  & ${VideoCallDecorate} {
    position: absolute;
    right: calc(100% + 68px);
    top: 0;

    @media (max-width: ${size.lg}) {
      right: calc(100% + 24px);
      width: 301px;
    }

    @media (max-width: ${size.md}) {
      width: 281px;
    }

    @media (max-width: ${size.sm}) {
      width: 215px;
      right: unset;
      display: block;
      position: relative;
      margin: auto;
      margin-bottom: 32px;
    }

    @media (max-width: ${size.xs}) {
      margin-bottom: 24px;
    }
  }
`
const Statistic = styled.div`
  display: flex;
  flex-direction: row;
  gap: 24px;

  @media (max-width: ${size.md}) {
    flex-direction: column;
  }

  @media (max-width: ${size.sm}) {
    gap: 32px;
  }

  @media (max-width: ${size.xs}) {
    gap: 24px;
  }
`

const GreenBackgroundText = styled(({ className }) => (
  <div className={className}>
    <Text.Large noResize semiBold>
      Совместная работа с психологом облегчает и без того трудный путь
      избавления от тревоги, дает надежную опору.
    </Text.Large>
  </div>
))`
  padding: 16px;
  padding-bottom: 44px;
  width: 384px;
  background: #e3f5f5;
  border-radius: 24px;

  @media (max-width: ${size.lg}) {
    padding-bottom: 16px;
  }

  @media (max-width: ${size.md}) {
    width: 100%;
  }
`

export const AnexityTechnique = styled(({ className }) => (
  <section className={className}>
    <Grid>
      <TextContent />
      <Statistic>
        <GreenBackgroundText />
        <StatisticNumbersList />
      </Statistic>
    </Grid>
  </section>
))`
  max-width: 100vw;
  background: ${color.background};
  padding-top: 105px;
  padding-bottom: 106px;
  position: relative;

  @media (max-width: ${size.lg}) {
    padding-top: 48px;
    padding-bottom: 80px;
  }

  @media (max-width: ${size.md}) {
    padding-top: 24px;
    padding-bottom: 37px;
  }

  @media (max-width: ${size.sm}) {
    padding-top: 18px;
    padding-bottom: 58px;
  }

  @media (max-width: ${size.xs}) {
    padding-top: 10px;
    padding-bottom: 40px;
  }

  & > ${Grid} {
    display: flex;
    flex-direction: column;
    gap: 179px;

    @media (max-width: ${size.lg}) {
      gap: 88px;
    }

    @media (max-width: ${size.md}) {
      gap: 48px;
    }

    @media (max-width: ${size.sm}) {
      gap: 32px;
    }

    @media (max-width: ${size.xs}) {
      gap: 24px;
    }
  }
`
