import React from 'react'
import styled from 'styled-components'
import { ReactComponent as CandleSVG } from './img/candle.svg'
import { ReactComponent as CloudsSVG } from './img/clouds.svg'
import { ReactComponent as DaytimeAnxietySVG } from './img/daytime-anxiety.svg'
import { ReactComponent as FriendsCommunicationSVG } from './img/friends-communication.svg'
import { ReactComponent as MaskSVG } from './img/mask.svg'
import { ReactComponent as PuzzleSVG } from './img/puzzle.svg'
import { ReactComponent as SearchSheetSVG } from './img/search-sheet.svg'
import { ReactComponent as SofaSVG } from './img/sofa.svg'
import { Text } from '../../atoms/Text'
import { color } from '../../styles/vars/colors'
import { size } from '../../constants'

const iconsList = [
  {
    Icon: FriendsCommunicationSVG,
    text: 'Общение с друзьями ухудшается'
  },
  {
    Icon: CandleSVG,
    text: 'Тяжело заводить новые знакомства'
  },
  {
    Icon: DaytimeAnxietySVG,
    text: 'В течение дня вы либо тревожитесь, либо боитесь, что будете тревожиться'
  },
  {
    Icon: MaskSVG,
    text: 'Ухудшается сон, становится тяжело засыпать'
  },
  {
    Icon: PuzzleSVG,
    text: 'Принятие решений/сборы превращаются в пытку'
  },
  {
    Icon: SofaSVG,
    text: 'Усиливается прокрастинация'
  },
  {
    Icon: SearchSheetSVG,
    text: 'Вы повторяете одни и те же действия, стараясь избежать тревоги (убираетесь, смотрите TikTok, грызете ногти и т.д.)'
  },
  {
    Icon: CloudsSVG,
    text: 'Переживания затмевают даже самые счастливые дни и события'
  }
]

export const IconsList = styled(({ className }) => (
  <ul className={className}>
    {iconsList.map(({ Icon, text }, index) => (
      <li key={index}>
        <Icon />
        <Text.Medium semiBold>{text}</Text.Medium>
      </li>
    ))}
  </ul>
))`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(2, auto);
  column-gap: 24px;
  row-gap: 64px;

  @media (max-width: ${size.lg}) {
    column-gap: 24px;
    row-gap: 40px;
  }

  @media (max-width: ${size.sm}) {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(4, auto);
    row-gap: 24px;
    column-gap: 16px;
  }

  @media (max-width: ${size.xs}) {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }

  & li {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 18px;

    @media (max-width: ${size.sm}) {
      gap: 8px;
    }
  }

  & > li > ${Text.Medium} {
    color: ${color.text.white};
  }
`
