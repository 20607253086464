import React from 'react'
import cloudBottom from './img/cloud-bottom.svg'
import cloudLeft from './img/cloud-left.svg'
import cloudRight from './img/cloud-right.svg'
import cloudTop from './img/cloud-top.svg'
import rain from './img/rain.svg'
import styled from 'styled-components'
import { ReactComponent as CloudEmptySVG } from './img/cloud-empty.svg'
import { Grid } from '../../atoms/Grid'
import { QuestionsList } from './QuestionsList'
import { Text } from '../../atoms/Text'
import { Title } from '../../atoms/Title'
import { maxWidth, size } from '../../constants'

const CloudEmptyDecorate = styled(CloudEmptySVG)``

const ClouBottom = styled(({ className }) => (
  <div className={className}>
    <Text.Medium semiBold>
      «Парень бросит меня, потому
      <br /> что больше не любит, ведь
      <br /> он не сразу ответил на мое
      <br /> сообщение»
    </Text.Medium>
  </div>
))`
  padding: 17px 21px 17px 70px;
  width: 450px;
  height: 150px;
  background: no-repeat url(${cloudBottom});

  @media (max-width: ${size.sm}) {
    &::after {
      content: url(${rain});
      position: absolute;
      right: 110px;
      top: 92px;
    }
  }
`

const CloudRight = styled(({ className }) => (
  <div className={className}>
    <Text.Medium semiBold>
      «У меня никогда не будет
      <br /> друзей, если они узнают, что я<br /> часто тревожусь»
    </Text.Medium>
  </div>
))`
  padding: 13px 31px 28px 39px;
  width: 450px;
  height: 150px;
  background: no-repeat url(${cloudRight});

  &::after {
    content: url(${rain});
    position: absolute;
    right: 130px;
    top: 72px;

    @media (max-width: ${size.sm}) {
      content: none;
    }
  }
`

const CloudLeft = styled(({ className }) => (
  <div className={className}>
    <Text.Medium semiBold>
      «Все люди будут осуждать
      <br /> меня и запомнят, какая я<br /> неряха, если на моем
      <br />
      платье будут складки»
    </Text.Medium>
  </div>
))`
  padding: 34px 126px 20px 92px;
  width: 450px;
  height: 150px;
  background: no-repeat url(${cloudLeft});
`

const CloudTop = styled(({ className }) => (
  <div className={className}>
    <Text.Medium semiBold>
      «Меня уволят с работы, потому
      <br /> что я сказал коллеге, что иногда
      <br /> я прерываюсь на ТикТок во
      <br /> время работы»
    </Text.Medium>
  </div>
))`
  padding: 30px 83px 37px 80px;
  width: 450px;
  height: 150px;
  background: no-repeat url(${cloudTop});
`

const Clouds = styled(({ className }) => (
  <div className={className}>
    <CloudTop />
    <CloudRight />
    <CloudLeft />
    <ClouBottom />
    <CloudEmptyDecorate />
  </div>
))`
  & > div,
  svg {
    position: absolute;
  }

  ${CloudTop} {
    right: 141px;
    top: 64px;

    @media (max-width: ${size.lg}) {
      right: -66px;
      top: 33px;
    }

    @media (max-width: ${size.md}) {
      right: 44px;
      top: 33px;
    }

    @media (max-width: ${size.sm}) {
      right: 0;
      top: 0;
    }

    @media (max-width: ${size.xs}) {
      right: -85px;
    }
  }

  ${CloudRight} {
    right: -21px;
    top: 253px;

    @media (max-width: ${size.lg}) {
      right: -116px;
      top: 277px;
    }

    @media (max-width: ${size.sm}) {
      right: -33px;
      top: 306px;
    }

    @media (max-width: ${size.xs}) {
      right: -126px;
      top: 326px;
    }
  }

  ${ClouBottom} {
    right: 300px;
    top: 342px;

    @media (max-width: ${size.lg}) {
      right: 169px;
      top: 361px;
    }

    @media (max-width: ${size.md}) {
      right: 269px;
      top: 361px;
    }

    @media (max-width: ${size.sm}) {
      right: -100px;
      top: 447px;
    }
  }

  ${CloudLeft} {
    right: 426px;
    top: 188px;

    @media (max-width: ${size.lg}) {
      right: 195px;
      top: 149px;
    }

    @media (max-width: ${size.md}) {
      right: 295px;
      top: 149px;
    }

    @media (max-width: ${size.sm}) {
      right: -118px;
      top: 149px;
    }

    @media (max-width: ${size.xs}) {
      top: 169px;
    }
  }

  ${CloudEmptyDecorate} {
    top: 188px;
    right: 0px;

    @media (max-width: ${size.lg}) {
      display: none;
    }

    @media (max-width: ${size.md}) {
      display: block;
      right: -111px;
    }

    @media (max-width: ${size.sm}) {
      display: none;
    }
  }
`

export const DealWithAnexity = styled(({ className }) => (
  <section className={className}>
    <Grid>
      <Title.H2asH1styles>
        Как справиться
        <br /> с тревогой?
      </Title.H2asH1styles>
      <Text.Large semiBold>
        Тревожные мысли часто рисуют нам картину самого худшего возможного
        будущего, но при этом маловероятного.
        <br />
        <br /> Но во время приступа беспокойства может быть сложно заметить
        искажения в своих рассуждениях и перестать тревожиться.
        <br />
        <br /> Умение заметить, что вы пересекаете черту нездоровой тревоги,
        остановить себя и включить в рассуждения рациональную часть — важный
        большой шаг в снижении количества тревоги в вашей жизни.
      </Text.Large>
      <Clouds />
      <QuestionsList />
    </Grid>
  </section>
))`
  max-width: 100%;
  position: relative;
  background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 1) 70%,
    rgba(239, 245, 251, 1) 100%
  );

  @media (max-width: ${size.sm}) {
    background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 1) 30%,
      rgba(239, 245, 251, 1) 100%
    );
  }

  ${Grid} {
    padding-top: 169px;
    padding-bottom: 114px;

    @media (max-width: ${size.lg}) {
      padding-top: 105px;
      padding-bottom: 114px;
    }

    @media (max-width: ${size.md}) {
      padding-top: 64px;
      padding-bottom: 72px;
    }

    @media (max-width: ${size.sm}) {
      padding-top: 47px;
      padding-bottom: 56px;
    }

    @media (max-width: ${size.xs}) {
      padding-top: 32px;
      padding-bottom: 24px;
    }
  }

  &::before {
    content: '';
    position: absolute;
    border-radius: 100% 100% 0 0 / 50px 50px 0 0;
    background: linear-gradient(
        180deg,
        rgba(255, 255, 255, 0) 49.86%,
        #ffffff 100%
      ),
      #e3f5f5;
    width: 100%;
    height: 530px;
    bottom: -56px;

    @media (max-width: ${size.md}) {
      height: 430px;
    }

    @media (max-width: ${size.sm}) {
      height: 530px;
    }
  }

  & ${Grid} > ${Title.H2asH1styles} {
    margin-bottom: 24px;
  }

  & ${Grid} > ${Text.Large} {
    max-width: 486px;

    @media (max-width: ${size.lg}) {
      max-width: 384px;
    }

    @media (max-width: ${size.md}) {
      max-width: unset;
    }
  }

  & ${Grid} > ${QuestionsList} {
    margin: 0 auto;
  }

  & ${Grid} > ${QuestionsList} {
    margin-top: 98px;

    @media (max-width: ${size.lg}) {
      margin-top: 110px;
    }

    @media (max-width: ${size.md}) {
      margin-top: 233px;
    }

    @media (max-width: ${size.sm}) {
      margin-top: 205px;
    }

    @media (max-width: ${size.xs}) {
      margin-top: 221px;
    }
  }

  & ${Clouds} {
    position: absolute;
    top: 0;
    right: calc((${maxWidth.lg} - ${size.lg}) / 2);

    @media (max-width: ${size.lg}) {
      right: calc((${maxWidth.md} - ${size.md}) / 2);
    }

    @media (max-width: ${size.md}) {
      right: 0;
      margin: 0 auto;
      position: relative;
      margin-top: 89px;
      height: 524px;
    }

    @media (max-width: ${size.sm}) {
      margin-top: 72px;
      height: 610px;
    }

    @media (max-width: ${size.xs}) {
      margin-top: 39px;
      height: 594px;
    }
  }
`
