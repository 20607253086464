import Modal from '../components/Modal'
import React, { useState } from 'react'
import ThxPopupContent from '../components/ThxPopupContent'
import giftSVG from '/static/img/gift.svg'
import sendMailSVG from '/static/img/send-mail.svg'
import styled from 'styled-components'
import { Button } from '../atoms/Button'
import { Email } from './Form/Input'
import { FORM_ERROR } from 'final-form'
import { Form as FinalForm } from 'react-final-form'
import { Event as GAEvent } from '../components/GA'
import { PublicSubscriptionTypes, sendSubscriptionRequest } from '../api'
import { Title } from '../atoms/Title'
import { documents } from '../../static/db.json'
import { motion } from 'framer-motion'
import { size } from '../constants'
import { submit } from '../atoms/useFormValues'

const subscription = {
  errors: true,
  values: true,
  submitting: true
}

const SendMailSVG = styled.img.attrs(() => ({
  src: sendMailSVG
}))`
  margin-bottom: 32px;
  margin-top: 32px;
`

const Form = styled(
  ({ className, errors, restart, submitting, handleSubmit }) => (
    <form
      className={className}
      errors={errors}
      onSubmit={submit({
        handleSubmit,
        restart,
        errors,
        initialValues: {}
      })}
    >
      <SendMailSVG />
      <Title.H3>Отправим гайд вам на почту</Title.H3>
      <Email />
      <p className="agreetext">
        Отправляя гайд на почту, я соглашаюсь на{' '}
        <a href={documents.urlPolicy} rel="noreferrer" target="_blank">
          обработку персональных данных
        </a>
        &nbsp;и&nbsp;с&nbsp;
        <a href={documents.urlAgreement} rel="noreferrer" target="_blank">
          условиями пользовательского соглашения
        </a>
      </p>
      <Button.NewPrimary
        disabled={errors.email}
        loading={submitting}
        type={submitting ? 'button' : 'submit'}
      >
        Отправить
      </Button.NewPrimary>
    </form>
  )
)`
  & .agreetext {
    color: #9b9b9b;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;

    & a {
      color: #9b9b9b;
      text-decoration: underline;

      &:hover {
        color: #575656ff;
      }
    }
  }

  & ${Title.H3} {
    margin-bottom: 16px;
  }

  & ${Email} {
    margin-bottom: 24px !important;
  }

  & ${Button.NewPrimary} {
    margin-top: 32px;

    @media (max-width: ${size.sm}) {
      width: 100%;
    }
  }
`

const useFormSubmit =
  ({ setState, type }) =>
  async (values) => {
    try {
      const body = await sendSubscriptionRequest({
        type,
        email: values.email
      })
      if (body.status === 200) {
        setState('success')
      }
    } catch (err) {
      console.error(err)
      return { [FORM_ERROR]: 'Failed' }
    }
  }

const animationProps = {
  animate: { opacity: 1 },
  initial: { opacity: 0 },
  transition: {
    type: 'spring',
    duration: 1
  }
}

const AnimatedDiv = ({ children, ...props }) => (
  <motion.div {...animationProps} {...props}>
    {children}
  </motion.div>
)

const getThxPopupStaticProps = (type) => {
  const anxiety = {
    bnsText: 'Спасибо',
    desc: 'Гайд по работе с тревогой отправлен на вашу почту',
    title: 'Успех!'
  }

  const panicAttack = {
    bnsText: 'Спасибо',
    desc: 'Памятка «Цикл панической атаки» скоро придет вам на почту',
    title: 'Успех!'
  }

  switch (type) {
    case PublicSubscriptionTypes.PANIC_ATTACK_NOTE:
      return panicAttack
    default:
      return anxiety
  }
}

export const GiveGuideModal = styled(({ className, hide, location, type }) => {
  const [state, setState] = useState('initial')
  const onSubmit = useFormSubmit({ setState, type })
  const thxPopupStaticProps = getThxPopupStaticProps(type)
  const handleSubmitAndSendGA = (handleSubmit, location) => {
    handleSubmit()
    GAEvent.getGuide('submit', location)
  }

  return (
    <Modal closeButtonInside bodyClassName={className} hide={hide}>
      <AnimatedDiv key={state}>
        {state === 'success' ? (
          <ThxPopupContent
            {...thxPopupStaticProps}
            hide={hide}
            imgSrc={giftSVG}
          />
        ) : (
          <FinalForm
            onSubmit={onSubmit}
            render={({
              handleSubmit,
              submitting,
              form: { restart },
              errors
            }) => (
              <Form
                errors={errors}
                handleSubmit={() =>
                  handleSubmitAndSendGA(handleSubmit, location)
                }
                restart={restart}
                submitting={submitting}
              />
            )}
            subscription={subscription}
          />
        )}
      </AnimatedDiv>
    </Modal>
  )
})``
