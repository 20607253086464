import React from 'react'
import styled from 'styled-components'
import { Montserrat, size } from '../../constants'
import { Text } from '../../atoms/Text'
import { color } from '../../styles/vars/colors'

const TextNumber = styled.p`
  font-family: ${Montserrat};
  font-style: normal;
  font-weight: 700 !important;
  font-size: 60px;
  line-height: 60px;
  color: ${color.text.pimaryBlue};
`

const list = [
  {
    number: '169+',
    text: 'психологов со средним опытом работы с тревожностью 6 лет'
  },
  {
    number: '2 500',
    text: 'людей обратились к нам с запросом на помощь с тревожностью'
  },
  {
    number: '15 500',
    text: 'онлайн-консультаций, посвященных работе над тревогой YouTalk провели по всему миру'
  }
]

export const StatisticNumbersList = styled(({ className }) => (
  <ul className={className}>
    {list.map(({ number, text }, index) => (
      <li key={index}>
        <TextNumber>{number}</TextNumber>
        <Text.Medium semiBold>{text}</Text.Medium>
      </li>
    ))}
  </ul>
))`
  display: grid;
  grid-template-columns: 180px 218px 282px;
  gap: 56px;

  @media (max-width: ${size.lg}) {
    gap: 24px;
    grid-template-columns: repeat(3, 222px);
  }

  @media (max-width: ${size.sm}) {
    display: flex;
    flex-direction: column;
    gap: 32px;
  }

  @media (max-width: ${size.xs}) {
    gap: 24px;
  }

  & li {
    display: flex;
    flex-direction: column;
    gap: 24px;

    @media (max-width: ${size.sm}) {
      gap: 16px;
    }

    @media (max-width: ${size.xs}) {
      gap: 8px;
    }
  }
`
