import React from 'react'
import styled from 'styled-components'
import yellowQuoteBackground from './img/yellow-quote.svg'
import yellowQuoteBackgroundSM from './img/yellow-quote-sm.svg'
import yellowQuoteBackgroundXS from './img/yellow-quote-xs.svg'
import yellowQuoteBackgroundXXS from './img/yellow-quote-xxs.svg'
import { ReactComponent as RoundsSVG } from './img/rounds.svg'
import { Title } from '../../atoms/Title'
import { ReactComponent as WhiteCrossSVG } from './img/white-cross.svg'
import { ReactComponent as YellowCrossSVG } from './img/yellow-cross.svg'
import { size } from '../../constants'

const YellowCrossDecorate = styled(YellowCrossSVG)``
const WhiteCrossDecorate = styled(WhiteCrossSVG)``
const RoundsDecorate = styled(RoundsSVG)``

export const YellowQuote = styled(({ className }) => (
  <div className={className}>
    <YellowCrossDecorate />
    <WhiteCrossDecorate />
    <RoundsDecorate />
    <Title.H3>
      К счастью, можно справиться с тревожностью с психологом или
      самостоятельно: снизить интенсивность тревоги, научиться не поддаваться
      тревожным мыслям и двигаться к своим целям, несмотря на переживания.
    </Title.H3>
  </div>
))`
  position: relative;
  padding: 127px 56px 69px 56px;
  background-image: url(${yellowQuoteBackground});
  background-repeat: no-repeat;
  z-index: 4;

  & > ${Title.H3} {
    color: #144c9b;
    max-width: 680px;

    @media (max-width: ${size.sm}) {
      max-width: 326px;
      font-size: 18px;
      line-height: 26px;
    }

    @media (max-width: ${size.xs}) {
      max-width: 252px;
      font-size: 16px;
      line-height: 24px;
    }
  }

  @media (max-width: ${size.md}) {
    background-image: url(${yellowQuoteBackgroundSM});
    padding: 127px 27px 69px 27px;
  }

  @media (max-width: ${size.sm}) {
    background-image: url(${yellowQuoteBackgroundXS});
    padding: 100px 24px 24px 24px;
  }

  @media (max-width: ${size.xs}) {
    background-image: url(${yellowQuoteBackgroundXXS});
    padding: 74px 16px 16px 16px;
  }

  ${YellowCrossDecorate} {
    position: absolute;
    top: 50%;
    left: -54px;

    @media (max-width: ${size.md}) {
      top: 15%;
      left: -8px;
    }
  }

  ${WhiteCrossDecorate} {
    position: absolute;
    top: 16px;
    left: 35%;

    @media (max-width: ${size.sm}) {
      left: 55%;
    }

    @media (max-width: ${size.xs}) {
      top: 4px;
    }
  }

  ${RoundsDecorate} {
    position: absolute;
    top: 0;
    left: 100%;

    @media (max-width: ${size.md}) {
      left: 90%;
    }

    @media (max-width: ${size.sm}) {
      left: 80%;
    }
  }

  @media (max-width: ${size.sm}) {
    ${YellowCrossDecorate}, ${WhiteCrossDecorate},  ${RoundsDecorate} {
      transform: scale(0.74);
    }
  }
`
