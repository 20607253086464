import React from 'react'
import styled from 'styled-components'
import { Grid } from '../../atoms/Grid'
import { IconsList } from './IconsList'
import { Title } from '../../atoms/Title'
import { YellowQuote } from '../../molecules/YellowQuote'
import { color } from '../../styles/vars/colors'
import { size } from '../../constants'

const BlueDecorate = styled.div`
  &::before {
    content: '';
    position: absolute;
    left: 0;
    height: calc(100% - 80px);
    width: 100%;
    background: #025e96;
    top: 78px;
    z-index: 0;

    @media (max-width: ${size.md}) {
      height: calc(100% - 56px);
      top: 54px;
    }

    @media (max-width: ${size.sm}) {
      height: calc(100% - 32px);
      top: 30px;
    }
  }
`

export const LifeDestruction = styled(({ className }) => (
  <section className={className}>
    <Grid>
      <Title.H2>
        Со временем тревога усиливается и вы можете поймать себя на том, что
        многие части вашей жизни разрушаются:
      </Title.H2>
      <IconsList />
      <YellowQuote />
    </Grid>
    <BlueDecorate />
  </section>
))`
  max-width: 100%;
  padding-top: 80px;
  background: #0063a8;
  position: relative;

  @media (max-width: ${size.md}) {
    padding-top: 56px;
  }

  @media (max-width: ${size.sm}) {
    padding-top: 32px;
  }

  &::before {
    content: '';
    position: absolute;
    left: 0;
    border-radius: 100% 100% 0% / 33% 33% 0% 0%;
    height: 80px;
    width: 100%;
    background: #025e96;
    top: -2px;

    @media (max-width: ${size.md}) {
      height: 56px;
    }

    @media (max-width: ${size.sm}) {
      height: 32px;
      border-radius: 0;
    }
  }

  &::after {
    content: '';
    position: absolute;
    border-radius: 50% 50% 0 0 / 100% 100% 0 0;
    background: #eff5fb;
    width: calc(100% + 64px);
    transform: translateX(-32px);
    height: 134px;
    bottom: -2px;
    z-index: 0;

    @media (max-width: ${size.lg}) {
      height: 160px;
      border-radius: 80% 80% 0 0 / 100% 100% 0 0;
    }

    @media (max-width: ${size.md}) {
      width: calc(100% + 16px);
      transform: translateX(-8px);
      border-radius: 10% 10% 0 0 / 10% 10% 0 0;
    }
  }

  & > ${Grid} > ${Title.H2} {
    color: ${color.text.white};
    max-width: 784px;
  }

  & > ${Grid} {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-direction: column;
    z-index: 1;
  }

  & > ${Grid} > ${IconsList} {
    margin-top: 94px;

    @media (max-width: ${size.md}) {
      margin-top: 32px;
    }

    @media (max-width: ${size.sm}) {
      margin-top: 55px;
    }

    @media (max-width: ${size.xs}) {
      margin-top: 32px;
    }
  }

  & > ${Grid} > ${YellowQuote} {
    margin: auto;
    position: relative;
    margin-top: 80px;

    @media (max-width: ${size.lg}) {
      margin-top: 32px;
    }

    @media (max-width: ${size.md}) {
      margin-top: -16px;
    }

    @media (max-width: ${size.sm}) {
      margin-top: 44px;
    }
  }
`
