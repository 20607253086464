import React from 'react'
import styled from 'styled-components'
import { ReactComponent as NoSVG } from './img/no.svg'
import { WizardCheckbox } from '../../atoms/Checkbox'
import { ReactComponent as YesSVG } from './img/yes.svg'

export const IconCheckboxYes = styled(({ className, children, ...props }) => (
  <WizardCheckbox className={className} {...props} Icon={<YesSVG />}>
    {children}
  </WizardCheckbox>
))``

export const IconCheckboxNo = styled(({ className, children, ...props }) => (
  <WizardCheckbox className={className} {...props} Icon={<NoSVG />}>
    {children}
  </WizardCheckbox>
))``
