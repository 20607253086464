export const SourceID = {
  // нужна помощь
  UC_8EOO3V: 'UC_8EOO3V',
  // сложный случай
  UC_EN2CT9: 'UC_EN2CT9',
  // самоподбор
  UC_12XK08: 'UC_12XK08',
  // нулевая выдача
  UC_F53YZF: 'UC_F53YZF',
  // регистрация после визарда в профиле либо просто WIZARD в битриксе
  UC_LWQXL8: 'UC_LWQXL8',
  WEB: 'WEB'
}
