import React, { useCallback, useEffect, useState } from 'react'
import questions from './questions.json'
import results from './results.json'
import styled from 'styled-components'
import useTogglePopup from '../../components/Modal/useTogglePopup'
import { AnexityTestForm } from '../../molecules/AnexityTestForm'
import { Button } from '../../atoms/Button'
import { ReactComponent as DepressionSVG } from './img/depression.svg'
import { Form, useFormState } from 'react-final-form'
import { Event as GAEvent } from '../../components/GA'
import { GiveGuideModal } from '../GiveGuideModal'
import { Grid } from '../../atoms/Grid'
import { NavigateButtonContainer } from './AnxietyTest.styles'
import { PublicSubscriptionTypes } from '../../api'
import { Text } from '../../atoms/Text'
import { Title } from '../../atoms/Title'
import { color } from '../../styles/vars/colors'
import { size } from '../../constants'

const StartText = styled(({ className }) => (
  <div className={className}>
    <Title.H3>Как распознать тревожное расстройство?</Title.H3>
    <Text.Large>
      {[Object.keys(questions).length, 'вопросов'].join(' ')}
    </Text.Large>
  </div>
))`
  display: flex;
  flex-direction: column;
  gap: 24px;

  @media (max-width: ${size.sm}) {
    gap: 16px;
  }

  & ${Text.Large} {
    color: ${color.text.greyDark};
  }
`

const handleGetTestClick = (action) => {
  action()
  GAEvent.anxietyTest('click', 'start')
}

const StartScreen = styled(({ className, goNext }) => (
  <div className={className}>
    <DepressionSVG />
    <StartText />
    <Button.NewPrimary
      onClick={useCallback(() => {
        handleGetTestClick(goNext)
      }, [])}
      size="large"
      type="button"
    >
      Пройти тест
    </Button.NewPrimary>
  </div>
))`
  background: ${color.background};
  box-shadow: 0px 30px 50px rgba(41, 99, 163, 0.1);
  border-radius: 16px;
  padding: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
  gap: 32px;

  @media (max-width: ${size.sm}) {
    width: 100%;
    gap: 24px;
    padding: 32px 24px;

    ${Button.NewPrimary} {
      width: 100%;
    }
  }

  @media (max-width: ${size.xs}) {
    width: 100%;
    gap: 16px;
    padding: 24px 16px;
  }
`

const getResult = (rightAnswers) =>
  [
    {
      condition: (rightAnswers) => rightAnswers <= 5,
      result: { range: '0-5', text: results['0-5'] }
    },
    {
      condition: (rightAnswers) => 5 < rightAnswers && rightAnswers <= 15,
      result: { range: '5-15', text: results['5-15'] }
    },
    {
      condition: (rightAnswers) => 15 < rightAnswers && rightAnswers <= 25,
      result: { range: '15-25', text: results['15-25'] }
    },
    {
      condition: (rightAnswers) => 25 < rightAnswers && rightAnswers <= 40,
      result: { range: '25-40', text: results['25-40'] }
    },
    {
      condition: (rightAnswers) => 40 < rightAnswers && rightAnswers <= 50,
      result: { range: '40-50', text: results['40-50'] }
    }
  ].find(({ condition }) => condition(rightAnswers))?.result

const ButtonGroup = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 16px;

  @media (max-width: ${size.sm}) {
    flex-direction: column;

    a,
    button {
      width: 100%;
    }
  }
`
const sendGAresults = (answersSum) => {
  if (answersSum <= 5) GAEvent.anxietyTest('finish', 'low')
  if (answersSum > 5 && answersSum <= 15)
    GAEvent.anxietyTest('finish', 'mediumLow')
  if (answersSum > 15 && answersSum <= 25)
    GAEvent.anxietyTest('finish', 'medium')
  if (answersSum > 25 && answersSum <= 40) GAEvent.anxietyTest('finish', 'high')
  if (answersSum > 40) GAEvent.anxietyTest('finish', 'veryHigh')
}
const ResultScreen = styled(({ className, goNext, rightAnswers }) => {
  const { isShowing, tglPopup } = useTogglePopup()
  const handleGetGuideClick = useCallback(() => {
    tglPopup()
    GAEvent.getGuide('click', 'anxietyTest')
  }, [tglPopup])
  useEffect(() => {
    sendGAresults(rightAnswers)
  }, [])

  return (
    <div className={className}>
      <Title.H3>{[getResult(rightAnswers).range, 'баллов'].join(' ')}</Title.H3>
      <Text.Average onClick={goNext} type="button">
        {getResult(rightAnswers).text}
      </Text.Average>
      <ButtonGroup>
        <Button.NewPrimary onClick={handleGetGuideClick} type="button">
          Получить гайд
        </Button.NewPrimary>
        <Button.NewOutline
          href="/form"
          onClick={useCallback(() => {
            GAEvent.getPsychologist('anxietyTest')
          }, [])}
          type="link"
        >
          Подобрать психолога
        </Button.NewOutline>
      </ButtonGroup>
      {isShowing && (
        <GiveGuideModal
          hide={tglPopup}
          location="anxietyTest"
          type={PublicSubscriptionTypes.ANXIETY_NOTE}
        />
      )}
    </div>
  )
})`
  display: flex;
  flex-direction: column;
  border-radius: 16px;
  background: ${color.background};
  box-shadow: 0px 30px 50px rgba(41, 99, 163, 0.1);
  text-align: center;
  align-items: center;
  justify-content: center;
  padding: 0 52px;

  @media (max-width: ${size.sm}) {
    padding: 0 24px;
  }

  @media (max-width: ${size.xs}) {
    padding: 0 16px;
  }

  & ${Title.H3} {
    margin-bottom: 24px;

    @media (max-width: ${size.sm}) {
      margin-bottom: 8px;
    }
  }

  & ${Text.Average} {
    margin-bottom: 32px;

    @media (max-width: ${size.sm}) {
      margin-bottom: 24px;
    }
  }
`

const getResultScore = (answers) =>
  [
    ...answers.slice(0, 13).filter((answer) => answer === 'no'),
    ...answers.slice(13, 50).filter((answer) => answer === 'yes')
  ].length

const StepForm = ({ step, goNext }) => {
  const { values } = useFormState()
  useEffect(() => {
    if (Object.keys(values?.answers ?? {}).length === step) goNext()
  }, [values?.answers])

  if (step === 'start') {
    return <StartScreen goNext={goNext} />
  }
  if (step === 'result') {
    return (
      <ResultScreen
        rightAnswers={getResultScore(Object.values(values?.answers))}
      />
    )
  }

  return (
    <AnexityTestForm
      question={questions[step]}
      questionsCount={Object.keys(questions).length}
      step={step}
    />
  )
}

const noop = () => null

export const AnexityTest = styled(({ className }) => {
  const [step, setStep] = useState('start')
  const goNext = useCallback(() => {
    if (step === 'start') {
      return setStep(1)
    }
    if (step === 50) {
      return setStep('result')
    }
    const timeoutId = setTimeout(() => setStep(step + 1), 100)

    return () => clearTimeout(timeoutId)
  }, [step])

  return (
    <section className={className}>
      <NavigateButtonContainer className="container">
        <Button.NewOutline
          href="/wizard/"
          onClick={() => {
            GAEvent.openNavigate('anxietyLanding')
          }}
          size="large"
          type="link"
        >
          Подобрать психолога
        </Button.NewOutline>
      </NavigateButtonContainer>
      <Grid>
        <Form
          onSubmit={noop}
          render={({ handleSubmit }) => (
            <form handleSubmit={handleSubmit}>
              <StepForm goNext={goNext} step={step} />
            </form>
          )}
          subscription={{ values: true }}
        />
      </Grid>
    </section>
  )
})`
  background: #eff5fb;
  padding-top: 32px;

  & form {
    position: relative;
    margin: 0 auto;
    height: 421px;
    width: 588px;

    @media (max-width: ${size.sm}) {
      height: 372px;
      width: 100%;
    }

    @media (max-width: ${size.xs}) {
      height: 370px;
    }
  }

  & form > div {
    position: absolute;
    top: 0;
    left: 0;
    margin: 0 auto;
    z-index: 3;
    height: 100%;
    width: 100%;
  }
`
