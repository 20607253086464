import React, { useCallback, useMemo } from 'react'
import styled from 'styled-components'
import { Button } from '../atoms/Button'
import { Event as GAEvent } from '../components/GA'
import { Grid } from '../atoms/Grid'
import { GridLegacyCards } from '../molecules/GridLegacyCards'
import { PsychologistMiniCardAnexity } from './PsychologistMiniCard'
import { Title } from '../atoms/Title'
import { color } from '../styles/vars/colors'
import { getCatalogQuery } from './CatalogContextProvider/hooks/useQuerySave'
import { isBrowser } from '../atoms/root'
import { randomChoice } from '../atoms/randomChoice'
import { selectDeviceSize } from '../state/reducers/deviceSlice'
import { size } from '../constants'
import { trackCatalogOpen } from '../components/amplitude/trackCatalogOpen'
import { useSelector } from 'react-redux'

const ButtonGroup = styled(({ className, workAreasIds }) => {
  const workAreasParamsQueryParams = useMemo(
    () =>
      getCatalogQuery({
        workAreas: workAreasIds.map(({ node }) => node.id)
      }),
    [workAreasIds]
  )

  return (
    <div className={className}>
      <Button.NewPrimary
        href={`/catalog/?${workAreasParamsQueryParams}`}
        onClick={useCallback(() => {
          GAEvent.openCatalog('anxietyLanding')
          trackCatalogOpen({
            source: 'main'
          })
        }, [])}
        size="large"
        type="link"
      >
        Открыть каталог
      </Button.NewPrimary>
      <Button.NewOutline
        transparent
        href="/form/"
        onClick={useCallback(() => {
          GAEvent.openForm('anxietyLanding')
        }, [])}
        size="large"
        type="link"
      >
        Заполнить анкету
      </Button.NewOutline>
    </div>
  )
})`
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 24px;

  @media (max-width: ${size.sm}) {
    flex-direction: column;
    gap: 16px;

    & ${Button.NewPrimary}, ${Button.NewOutline} {
      width: 100%;
    }
  }
`

const GROUP_TYPE = 'ANXIETY_STATES'

const getOnlyAnexityFeatures = (psychologists) =>
  psychologists.map((psychologist) => ({
    ...psychologist,
    features: (psychologist.work_areas ?? psychologist.workAreas)
      .filter(({ group }) => group?.type === GROUP_TYPE)
      .map(({ tag }) => tag)
      .join('\n')
  }))

export const AnexityPsychologists = styled(
  ({ className, data, title = 'Подходящие психологи' }) => {
    const windowWidth = useSelector(selectDeviceSize)
    const psychologists = useMemo(
      () => randomChoice(data.psychologists.edges.map(({ node }) => node)),
      [data.psychologist]
    )

    const countByWindowWidth = useMemo(
      () => (windowWidth < 1023 ? 2 : 3),
      [windowWidth]
    )

    const psychologistSliced = useMemo(
      () =>
        isBrowser
          ? getOnlyAnexityFeatures(psychologists.slice(0, countByWindowWidth))
          : [],
      [countByWindowWidth]
    )

    return (
      <section className={className}>
        <Grid>
          <Title.H2asH1styles>{title}</Title.H2asH1styles>
          <GridLegacyCards>
            {psychologistSliced.map((psychologist) => (
              <PsychologistMiniCardAnexity
                key={psychologist.id}
                psychologist={psychologist}
              />
            ))}
          </GridLegacyCards>
          <ButtonGroup workAreasIds={data.workAreas.edges ?? []} />
        </Grid>
      </section>
    )
  }
)`
  background: #e6f0fd;
  padding-top: 72px;
  padding-bottom: 96px;

  @media (max-width: ${size.lg}) {
    padding-bottom: 72px;
  }

  @media (max-width: ${size.md}) {
    padding-top: 48px;
    padding-bottom: 48px;
  }

  @media (max-width: ${size.sm}) {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  @media (max-width: ${size.xs}) {
    padding-top: 32px;
    padding-bottom: 32px;
  }

  & ${PsychologistMiniCardAnexity} {
    background: ${color.background};
    height: 468px;
  }

  & ${Title.H2asH1styles} {
    margin-bottom: 32px;

    @media (max-width: ${size.md}) {
      margin-bottom: 24px;
    }
  }

  & ${GridLegacyCards} {
    margin-bottom: 56px;

    @media (max-width: ${size.md}) {
      margin-bottom: 40px;
    }

    @media (max-width: ${size.xs}) {
      margin-bottom: 32px;
    }
  }
`
