import React from 'react'
import styled from 'styled-components'
import { Field } from 'react-final-form'
import { IconCheckboxNo, IconCheckboxYes } from '../IconCheckbox'
import { Text } from '../../atoms/Text'
import { Title } from '../../atoms/Title'
import { color } from '../../styles/vars/colors'
import { motion } from 'framer-motion'
import { size } from '../../constants'

const Checkboxes = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
  width: 100%;

  @media (max-width: ${size.sm}) {
    display: flex;
    flex-direction: column;
  }
`

const animationProps = {
  animate: { opacity: 1 },
  initial: { opacity: 0 },
  transition: {
    type: 'spring'
  }
}

export const AnexityTestForm = styled(
  ({ className, question, step, questionsCount }) => (
    <div className={className}>
      <motion.div key={step} {...animationProps}>
        <Text.Large> {[step, questionsCount].join('/')}</Text.Large>
        <Title.H3 {...animationProps}>{question}</Title.H3>
        <Checkboxes>
          <Field name={`answers.answer${step}`} type="radio" value="yes">
            {({ input }) => (
              <IconCheckboxYes {...input} id="yes">
                Да
              </IconCheckboxYes>
            )}
          </Field>
          <Field name={`answers.answer${step}`} type="radio" value="no">
            {({ input }) => (
              <IconCheckboxNo {...input} id="no">
                Нет
              </IconCheckboxNo>
            )}
          </Field>
        </Checkboxes>
      </motion.div>
    </div>
  )
)`
  border-radius: 16px;
  background: ${color.background};
  box-shadow: 0px 30px 50px rgba(41, 99, 163, 0.1);

  & > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 48px;

    padding: 64px 32px 0 32px;

    @media (max-width: ${size.sm}) {
      gap: 32px;
      padding: 32px 24px 0 24px;
    }

    @media (max-width: ${size.sm}) {
      padding: 24px 16px 0 16px;
    }
  }

  ${Text.Large} {
    color: ${color.text.greyDark};
  }
`
