import React from 'react'
import styled from 'styled-components'
import { CheckMarkListItem } from '../../atoms/CheckMarkListItem'
import { ReactComponent as GirlWithUmbrellaSVG } from './img/girl-with-umbrella.svg'
import { ReactComponent as GirlWithUmbrellaXSSVG } from './img/girl-with-umbrella-xs.svg'
import { ReactComponent as GirlWithUmbrellaXXSSVG } from './img/girl-with-umbrella-xxs.svg'
import { ReactComponent as HeartBlueSunMobileSVG } from './img/heart-blue-sun-mobile.svg'
import { ReactComponent as HeartBlueSunSVG } from './img/heart-blue-sun.svg'
import { Title } from '../../atoms/Title'
import { color } from '../../styles/vars/colors'
import { size } from '../../constants'

const HeartBlueSunIcon = styled(HeartBlueSunSVG)``
const HeartBlueSunMobileIcon = styled(HeartBlueSunMobileSVG)``
const GirlWithUmbrellaDecorate = styled(GirlWithUmbrellaSVG)``
const GirlWithUmbrellaXSDecorate = styled(GirlWithUmbrellaXSSVG)``
const GirlWithUmbrellaXXSDecorate = styled(GirlWithUmbrellaXXSSVG)``

const questionList = [
  'Исход, который я сейчас продумываю в своих мыслях — единственный?',
  'Какие альтернативные варианты развития событий?',
  'Какой вариант самый лучший? А самый худший?',
  'Могу ли я с помощью тревожных мыслей повлиять на исход?',
  'Чем бы я предпочел заняться вместо фокусирования на тревожных мыслях?'
]

export const QuestionsList = styled(({ className }) => (
  <div className={className}>
    <HeartBlueSunIcon />
    <HeartBlueSunMobileIcon />
    <GirlWithUmbrellaDecorate />
    <GirlWithUmbrellaXSDecorate />
    <GirlWithUmbrellaXXSDecorate />
    <Title.H3>В такой момент вы можете задать себе вопросы:</Title.H3>
    <ul>
      {questionList.map((question, index) => (
        <CheckMarkListItem key={index} semiBold text={question} />
      ))}
    </ul>
  </div>
))`
  padding: 62px 102px 64px;
  position: relative;
  border: 1px solid #dedede;
  background: ${color.background};
  border-radius: 24px;
  max-width: 792px;
  z-index: 1;

  @media (max-width: ${size.lg}) {
    padding: 64px 80px;
  }

  @media (max-width: ${size.md}) {
    padding: 62px 24px 56px 24px;
  }

  @media (max-width: ${size.sm}) {
    padding: 54px 16px 32px 16px;
  }

  @media (max-width: ${size.xs}) {
    padding: 40px 16px 24px 16px;
  }

  & ${GirlWithUmbrellaDecorate} {
    position: absolute;
    margin: 0 auto;
    top: -317px;
    left: calc(100% - 300px);
    z-index: 0;

    @media (max-width: ${size.sm}) {
      display: none;
    }
  }

  & ${GirlWithUmbrellaXSDecorate} {
    display: none;

    @media (max-width: ${size.sm}) {
      position: absolute;
      display: block;
      left: 16px;
      top: -220px;
    }

    @media (max-width: ${size.xs}) {
      display: none;
    }
  }

  & ${GirlWithUmbrellaXXSDecorate} {
    display: none;

    @media (max-width: ${size.xs}) {
      position: absolute;
      display: block;
      left: 16px;
      top: -192px;
    }
  }

  & ${HeartBlueSunIcon} {
    position: absolute;
    top: -60px;
    left: calc(50% - 50px);

    @media (max-width: ${size.sm}) {
      display: none;
    }
  }

  & ${HeartBlueSunMobileIcon} {
    display: none;

    @media (max-width: ${size.sm}) {
      position: absolute;
      top: -24px;
      left: calc(50% - 27px);
      display: block;
    }
  }

  & ${Title.H3} {
    margin-bottom: 24px;
  }

  & > ul {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }
`
