import React from 'react'
import styled from 'styled-components'
import { ReactComponent as CloudLeftSVG } from './img/cloud-left.svg'
import { ReactComponent as CloudMiddleSVG } from './img/cloud-middle.svg'
import { ReactComponent as CloudRightSVG } from './img/cloud-right.svg'
import { Grid } from '../../atoms/Grid'
import { Text } from '../../atoms/Text'
import { Title } from '../../atoms/Title'
import { color } from '../../styles/vars/colors'
import { maxWidth, size } from '../../constants'

const CloudMiddleDecorate = styled(CloudMiddleSVG)``
const CloudLeftDecorate = styled(CloudLeftSVG)``
const CloudRightDecorate = styled(CloudRightSVG)``

export const AboutPsychologistHelp = styled(({ className }) => (
  <section className={className}>
    <Grid>
      <CloudLeftDecorate />
      <CloudRightDecorate />
      <CloudMiddleDecorate />
      <Title.H2asH1styles>
        Чем поможет психологическая помощь при тревоге?
      </Title.H2asH1styles>
      <Text.Large noResize semiBold>
        Тревога не одинаковая у всех. Каждый тревожный человек сталкивается со
        своей, особенной тревогой: ее силой, триггерами, формой и
        направленностью. Любые советы специалистов в интернете обобщенные, не
        учитывающие нюансов вашего состояния. Поэтому они могут работать
        недостаточно эффективно, а некоторые даже вредить.
      </Text.Large>
    </Grid>
  </section>
))`
  position: relative;
  background: #03b2a5;
  padding-top: 78px;
  padding-bottom: 154px;

  @media (max-width: ${size.lg}) {
    padding-bottom: 98px;
  }

  @media (max-width: ${size.md}) {
    padding-top: 48px;
    padding-bottom: 60px;
  }

  @media (max-width: ${size.sm}) {
    padding-top: 32px;
    padding-bottom: 42px;
  }

  @media (max-width: ${size.xs}) {
    padding-bottom: 62px;
  }

  &::before {
    content: '';
    position: absolute;
    border-radius: 100% 100% 0 0 / 50px 50px 0 0;
    background: ${color.background};
    width: 100%;
    height: 18px;
    bottom: -2px;

    @media (max-width: ${size.sm}) {
      border-radius: 30% 30% 0 0 / 8px 8px 0 0;
    }
  }

  & ${Grid} {
    display: grid;
    gap: 24px;
    grid-template-columns: 1fr 1fr;

    @media (max-width: ${size.md}) {
      display: flex;
      flex-direction: column;
      gap: 16px;
    }
  }

  & ${Grid} > ${Title.H2asH1styles}, ${Text.Large} {
    color: ${color.text.white};
  }

  & ${Grid} > ${Text.Large} {
    padding-top: 15px;

    @media (max-width: ${size.md}) {
      padding-top: 0;
      max-width: 666px;
    }

    @media (max-width: ${size.sm}) {
      max-width: unset;
    }
  }

  & ${CloudLeftDecorate}, ${CloudRightDecorate}, ${CloudMiddleDecorate} {
    position: absolute;
  }

  & ${CloudLeftDecorate} {
    top: 120%;
    left: calc(${maxWidth.lg} - 100% - 144px);

    @media (max-width: ${size.lg}) {
      top: 108%;
      left: calc(${maxWidth.md} - 100% - 144px);
    }

    @media (max-width: ${size.md}) {
      top: 95%;
      left: -194px;
    }

    @media (max-width: ${size.sm}) {
      display: none;
    }
  }

  & ${CloudMiddleDecorate} {
    top: 120%;
    left: calc(${maxWidth.lg} - 60%);

    @media (max-width: ${size.lg}) {
      top: 108%;
      left: calc(${maxWidth.md} - 60%);
    }

    @media (max-width: ${size.md}) {
      top: 100%;
      left: calc(${maxWidth.sm} - 48px);
    }

    @media (max-width: ${size.sm}) {
      top: 296px;
      left: 90%;
    }

    @media (max-width: ${size.xs}) {
      display: none;
    }
  }

  & ${CloudRightDecorate} {
    top: -48px;
    left: calc(${maxWidth.lg} - 72px);

    @media (max-width: ${size.lg}) {
      top: -32px;
      left: calc(${maxWidth.md} - 72px);
    }

    @media (max-width: ${size.md}) {
      top: 32px;
      left: calc(${maxWidth.sm} - 72px);
    }

    @media (max-width: ${size.sm}) {
      top: 48px;
      left: 70%;
    }

    @media (max-width: ${size.xs}) {
      top: 48px;
      left: 90%;
    }
  }
`
