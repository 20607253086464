import React from 'react'
import checkbox from './img/checkbox.svg'
import styled from 'styled-components'
import { Text } from '../Text'
import { size } from '../../constants'

export const CheckMarkListItem = styled(
  ({ className, text, semiBold = false }) => (
    <li className={className}>
      <Text.Medium semiBold={semiBold}>
        <img alt="checkbox" src={checkbox} />
        {text}
      </Text.Medium>
    </li>
  )
)`
  list-style-type: none;
  position: relative;
  display: inline-block;
  min-width: 100%;
  margin-top: 0;
  overflow: hidden;

  @media (max-width: ${size.sm}) {
    width: 100%;
    padding-right: 0;
  }

  ${Text.Medium} {
    margin-left: 40px;
    white-space: wrap;
  }

  & img {
    position: absolute;
    top: 4px;
    left: 0;
    margin-right: 16px;
  }
`
